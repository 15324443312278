import axios from "axios";
import { GET_VERSIONS_URL } from "../ApiConstant";

export const versionsLoading = () => {
  return {
    type: "LOADING_VERSIONS",
  };
};

export const versionsSuccess = (data) => {
  return {
    type: "VERSIONS_SUCCESS",
    data: data,
  };
};

export const versionsError = (err) => {
  return {
    type: "VERSIONS_ERROR",
    err: err,
  };
};

// export const getVersions = () => {
//   return (dispatch) => {
//     dispatch(versionsLoading());
//     axios
//       .get(GET_VERSIONS_URL)
//       .then((resp) => {
//         dispatch(versionsSuccess(resp.data));
//         localStorage.setItem("contentVersion", JSON.stringify(resp.data));
//       })
//       .catch((err) => {
//         dispatch(versionsError(err));
//       });
//   };
// };
export const getVersions = () => {

  return (dispatch) => {
    const cachedVersion = localStorage.getItem("contentVersion");
    if (cachedVersion) {
      dispatch(versionsSuccess(JSON.parse(cachedVersion)));
      return;
    }else{
      let jsn = JSON.stringify({"id":14,"url":"new-balysbet.com/","cdn_url":"siteContent/new-balysbet.com/","en":"2.2","cn":"1.6","hin":"1.5","telugu":"1.5","tamil":"1.5","arabic":"1.1","japanese":"1.1","korean":"1.1","thai":"1.1","modifyDate":"2024-05-26T20:24:20.000Z","domain":"ballys.itone.biz"}) 
      localStorage.setItem("contentVersion", jsn);
      dispatch(versionsSuccess(jsn));
    }

    dispatch(versionsLoading());
    axios
      .get(GET_VERSIONS_URL)
      .then((resp) => {
        dispatch(versionsSuccess(resp.data));
        localStorage.setItem("contentVersion", JSON.stringify(resp.data));
        
      })
      .catch((err) => {
        const errorMessage = err.response?.data?.message || err.message || "Unknown error occurred";
        dispatch(versionsError(errorMessage));
      });
  };
};

