import { useEffect, useState, useRef } from "react";
import { useTableConfigNotice } from "../../services/config";
import { IMGBUCKETURL } from "../../DataStore/ApiConstant";

const PoyaBanneer = () => {
  let poya = useTableConfigNotice();
  const [active, setActive] = useState(false);
  const [image, setImage] = useState("");
  const modalRef = useRef(null);

  // Close modal if clicking outside
  useEffect(() => {
    !poya.isLoading && poya?.data?.length > 0 &&
    poya?.data.forEach(item => {
      if (item.type === "poya") {
              setActive(true)
              setImage(item.image)
            }
        });
    
    },[poya.isLoading])
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setActive(false);
      }
    };

    if (active) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [active]);

  return (
    <>
      {active && (
        <div
          className={`modal fade ${active ? "show d-block" : ""}`} // Ensures modal is visible
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          style={{ backgroundColor: "#000000a1" }}
        >
          <div
            ref={modalRef}
            className="modal-dialog modal-dialog-scrollable modal-lg showModalcontent"
          >
            <div className="modal-content" style={{ backgroundColor: "transparent" }}>
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close ms-auto" // Moves button to right side
                  onClick={() => setActive(false)}
                  style={{
                    backgroundColor: "white",
                    position: "relative", // Ensures it stays inside modal header
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <img
                  className="img-fluid"
                  alt=""
                  src={`${IMGBUCKETURL + image}`}
                />
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PoyaBanneer;
