import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  BASE_URL,
  FUND_TRANSFER_TYPE_URL,
  SPORT_LOGIN_URL,
  POINTS_URL,
  IMAGE_BUCKET_URL
} from "../../DataStore/ApiConstant";
import { connect } from "react-redux";
import _ from "lodash";
import TranslatesContent from "../../DataStore/Languages/translatesContent";
import { usePointsDetails } from "../../services/cashier";
import { usePromotions } from "../../services/promotions";
import { promotion } from "../../DataStore/atom";
import { useAtom } from "jotai";

import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.min.css";
//   useEffect(() => {

//   }, []);

// Calculate yesterday's and today's dates in YYYY-MM-DD format
const getFormattedDate = (date) => {
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, "0");
  const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const year = d.getFullYear();
  return `${year}-${month}-${day}`;
};

const Points = ({ setSelectedGameType }) => {
  const history = useHistory();
  const pointsDetails = usePointsDetails();
  const upcomingPromotion = usePromotions();
  const [promo, setPromo] = useAtom(promotion);
  let points = 0;
  let upcomigPromo = !upcomingPromotion.isLoading && upcomingPromotion?.data?.filter((item) => item.upcoming_promotion == 1);

  const today = getFormattedDate(new Date());
  const yesterday = getFormattedDate(new Date(Date.now() - 86400000)); // 86400000 ms = 1 day

  const [fromDate, setFromDate] = useState(yesterday);
  const [toDate, setToDate] = useState(today);
  const [err, setErr] = useState("");
  const [pointsAvailable, setPointsAvailable] = useState("");

  const gameWrapperArea = useRef(null);

  const setPromtion = (type, promotions) => {
    history.push("/lobby");
    gameWrapperArea.current?.scrollIntoView({ behavior: "smooth" });
    setSelectedGameType(type);
    setPromo(promotions);
  };

  const handleDateChange = (date) => {
    setFromDate(date);
  };
  const handleToDateChange = (date) => {
    setToDate(date);
  };

  const submitPoints = (e) => {
    e.preventDefault();
    if (fromDate == "" || toDate == "") {
      setErr("Please enter a valid date range");
      setTimeout(() => {
        setErr("");
      }, 4000);
      return;
    }
    axios
      .get(POINTS_URL + `?startdate=${fromDate}&enddate=${toDate}`)
      .then((resp) => {
        if (resp?.data?.points > 0) {
          points = Math.floor(resp?.data?.points);
        }
        setPointsAvailable(points);
      })
      .catch((err) => {});
  };

  return (
    <>
      <div className="row p-4">
        <div className="col-12 col-lg-10 col-md-8 col-sm-8 col-xl-10 offset-xl-1 offset-lg-1 offset-md-2 offset-sm-2 offset-0">
          <div className="row">
            <div className="d-flex justify-content-center flex-column">
              <div className="p-2 text-center" style={{ fontSize: "1.3rem", fontWeight: "400" }}>Date Range</div>
              
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-6 col-lg-6 col-sm-6 justify-content-center align-self-center offset-xl-3 offset-lg-3 offset-md-3 offset-sm-3 offset-0">

            <div className="d-flex justify-content-center">
              <div className="date-picker-container">
                <label htmlFor="formGroupExampleInput" className="form-label">
                  From
                </label>
                {/* <input 
                type="text" 
                id="dateInput" 
                placeholder="Select Date"
                onChange={(e) => handleDateChange(e)}
                min="1900-01-01"
                value={fromDate}
                /> */}
                  <Flatpickr
                    id="dateInput"
                    // value={fromDate}
                    placeholder="DD/MM/YYYY"
                    onChange={(selectedDates, dateStr) => handleDateChange(dateStr)}
                    options={{
                      dateFormat: "d-m-Y", 
                    }}
                  />

                <span className="calendar-icon">
                  <i
                    className="fas fa-calendar-alt calander-points"
                  />
                </span>
              </div>
              <div className="date-picker-container">
                <label htmlFor="formGroupExampleInput" className="form-label">
                  To
                </label>
                <Flatpickr
                    id="dateInput"
                    // value={toDate}
                    onChange={(selectedDates, dateStr) => handleToDateChange(dateStr)} 
                    options={{
                      dateFormat: "d-m-Y",
                    }}
                    placeholder="DD/MM/YYYY"
                  />
                {/* <input 
                type="text" 
                id="dateInput" 
                placeholder="Select Date"
                onChange={(e) => handleToDateChange(e)}
                min="1900-01-01"
                value={toDate}
                /> */}
                <span className="calendar-icon">
                  <i
                    className="fas fa-calendar-alt calander-points"
                  />
                </span>
              </div>
            </div>
            <div className="p-2 text-center" style={{ fontSize: "1.3rem", fontWeight: "400" }}>Total Points</div>
            <div className="p-2 text-center" style={{ fontSize: "1.3rem", fontWeight: "600", color: "#000 !important" }}>
                {pointsAvailable || 0}
              </div>

              <div className="p-2 text-center">
                <h4 style={{fontSize: '0.9rem', fontWeight: 600, color: '#000 !important'}}>
                 This points will be calculated from start day early morning 00 hrs to end day midniight 12 hrs
                </h4>
              </div>


              {/* <div className="d-flex justify-content-center">
                <div className="p-2 text-center">
                  <label htmlFor="formGroupExampleInput" className="form-label">
                    From
                  </label>
                  <input
                    onChange={(e) => handleDateChange(e)}
                    type="date"
                    className="form-control"
                    name="from"
                    min="1900-01-01"
                    value={fromDate}
                  />
                </div>
                <div className="p-2 text-center">
                  <label htmlFor="formGroupExampleInput" className="form-label">
                    To
                  </label>
                  <input
                    onChange={(e) => handleToDateChange(e)}
                    type="date"
                    className="form-control"
                    name="to"
                    min="1900-01-01"
                    value={toDate}
                  />
                </div>
              </div> */}
              <div className="row row d-flex justify-content-center my-1">
                <div className="col-md-4 mb-3 mt-3">
                  <div className="row">
                    <a href="#">
                      <div onClick={(e) => submitPoints(e)} className="btn btn-primary btn-lg w-100"> SUBMIT</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const BindStateToProps = (state) => {
  return {
    siteImages: state.siteImages
  };
};

export default connect(BindStateToProps)(Points);
