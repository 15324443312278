import axios from "axios";
import { LANGUAGE_URL, BASE_URL } from "../ApiConstant";
// import enContent from '../../Assets/json/en.json';
// import cnContent from '../../Assets/json/cn.json';
// import hinContent from '../../Assets/json/hin.json';
// import tamilContent from '../../Assets/json/tamil.json';
// import teluguContent from '../../Assets/json/telugu.json';

export const LanguageLoading = () => {
  return {
    type: "LANGUAGE_LOADING",
  };
};

export const LanguageSuccess = (data) => {
  return {
    type: "LANGUAGE_SUCCESS",
    data: data,
  };
};

export const LanguageError = (err) => {
  return {
    type: "LANGUAGE_ERROR",
    err: err,
  };
};

export const CurrentLanguage = (currentLang) => {
  return {
    type: "CURRENT_LANGUAGE",
    language: currentLang,
  };
};

export const LanguageContent = (langContent) => {
  return {
    type: "LANGUAGE_CONTENT",
    content: langContent,
  };
};

const getLanguageCont = (currentLang = "en", callback) => {
  let ver = JSON.parse(localStorage.getItem("contentVersion"));
  let url = `${BASE_URL + ver["cdn_url"] + currentLang}@${
    ver[currentLang]
  }.json`;
  
  fetch(url)
    .then((response) => response.json())
    .then((json) => {
      callback(json);
    });
};

export const GetLanguages = () => {
  
  return (dispatch) => {
    dispatch(LanguageLoading());
    axios
      .get(LANGUAGE_URL)
      .then((resond) => {
        dispatch(LanguageSuccess(resond));
        let currentLang = "en";

        localStorage.getItem("currentLanguage")
          ? (currentLang = localStorage.getItem("currentLanguage"))
          : (currentLang = resond.data[0].code3);

          dispatch(CurrentLanguage(currentLang));
          getLanguageCont(currentLang, (resp) => {
          dispatch(LanguageContent(resp));
          
        });
      })
      .catch((err) => {
        dispatch(LanguageError(err));
        
      });
  };
};

export const setCurrentLanguage = (currentLang) => {
  localStorage.setItem("currentLanguage", currentLang);
  return (dispatch) => {
    dispatch(CurrentLanguage(currentLang));
    getLanguageCont(currentLang, (resp) => {
      dispatch(LanguageContent(resp));
    });
  };
};
