import { IMAGE_BUCKET_URL } from "../../DataStore/ApiConstant";
import WhatsappButton from "../Cashier/whatsappButton";
import ImportantNotice from "./ImportantNotice";
import TranslatesContent from "../../DataStore/Languages/translatesContent";
import BonusOffer from "../TransactionCommon/BonusOffer";
import { useQRPayment } from "../../services/cashier";

const QRPayment = () => {
  const QR = useQRPayment();
  const QRimage = !QR.isLoading && QR.data?.[0]?.value != 0 ? QR.data?.[0]?.value : null


  return (
    <div
      className="tab-pane fade"
      id="v-pills-QR"
      role="tabpanel"
      aria-labelledby="v-pills-settings-tab"
    >
      <div className="row d-flex justify-content-center">
        <label
          htmlFor="exampleFormControlInput1"
          className="cashierNewTitel text-center"
        >
          QR Payments
        </label>
        <div className="col-md-12 col-12 py-2 text-center">
          {QR.isLoading &&  <p>Loading...</p>}
        
          {QRimage ? (<img
              src={`${IMAGE_BUCKET_URL}${QRimage}`}
              className="img-fluid"
            />) : ""}

              {/* {!QRImage.isLoading && QRImage.data?.[0]?.value && (
                <img
                  src={`${IMAGE_BUCKET_URL}${QRImage.data[0].value}`}
                  className="img-fluid"
                />
              )} */}
              {/* <img
              src={`https://storage.googleapis.com/itonecdn/ballysimgWeb/new-web/qr-sample.png`}
              className="img-fluid"
            /> */}
        </div>

      </div>
      {/* <WhatsappButton /> */}
    </div>
  );
};

export default QRPayment;
